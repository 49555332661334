@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
* {
  font-family: "Poppins", sans-serif;
}
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #fdd231;
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
html,
body {
  margin: 0;
  margin: auto;
  font-family: "Poppins", sans-serif;
  background: #1a202c;
  color: #efefef;
}
#root {
  display: flex;
  flex-direction: column;
  position: relative;
}
a {
  color: inherit;
  text-decoration: none;
}

.App,
.root {
  position: relative;
  height: 100%;
}
.header {
  flex-direction: column;
  text-align: right;
  position: relative;
  max-width: 1030px;
  padding: 10px 0px;
  display: flex;
  margin: 0px auto;
  width: 100%;
  justify-content: center;
}
h1 {
  padding: 10px;
  margin: 0;
}
.title {
  padding: 30px 0px;
}
.logo {
  /* color: rgb(44, 255, 37); */
  /* color:#eee; */
  padding: 10px;
  font-weight: 800;
  font-size: 24px;
  text-decoration: none;
  /* border:1px solid #555; */
  /* box-shadow:0px 0px 100px 0px rgba(87, 87, 87, 0.1); */
  border-radius: 4px;
}

.line-bg {
  position: relative;
  display: inline-block;
  padding: 10px;
}

.line-bg:after {
  position: absolute;
  top: 50%;
  left: 5px;
  right: 5px;
  height: 14px;
  margin-top: -5px;
  content: "";
  z-index: -1;
  background: #ffd56a !important;
}

ul {
  list-style: none;
}

.nav-links {
  display: inline-block;
  padding: 10px 20px;
}

.bottom {
  position: fixed;
  bottom: 0%;
  padding: 10px;
  width: 100%;
  left: 0%;
  text-align: right;
}
.icon {
  padding: 10px;
}
.clock {
  text-align: right;
  color: #3d4345;
  font-weight: 300;
  float: right;
}
.clock .time {
  display: block;
  clear: both;
  font-size: 42px;
  height: 50px;
  line-height: 50px;
}
.clock .date {
  display: block;
  clear: both;
  font-size: 14px;
  margin-top: 0px;
  line-height: 20px;
}

/* Footer */
footer {
  position: relative;
  max-width: 1030px;
  padding: 10px 0px;
  display: flex;
  margin: 0px auto;
  width: 100%;
  justify-content: center;
}

li.footer {
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 600;
  margin: 0 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  letter-spacing: 0.05rem;
}

a.footer,
span.footer {
  color: #868e96;
  transition: 0.2s;
  cursor: pointer;
}

.social.footer {
  text-align: right;
}

.social-item.footer:first-child {
  border-left: 0;
}

.social-item.footer {
  display: inline-block;
  margin-left: 10px;
  font-size: 0.75rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
}

p.footer {
  font-size: 0.75rem;
  margin-top: 10px;
  font-weight: 400;
  color: #868e96;
  text-align: right;
}

